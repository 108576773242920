import React from 'react';
import { Todo } from './Todo';
import Button from 'ui/elements/buttons/Button';
import { Network } from 'ui/illustrations/Illustrations.stories';
import BoostFundingRoundButton from 'domain/companies/BoostFundingRoundButton';
import useLocalStorage from 'hooks/useLocalStorage';

export default function PromoteInvestorMatching({ companyId }: { companyId: number }) {
  const [promoteInvestorMatching, setPromoteInvestorMatching] = useLocalStorage(
    `promote-investor-matching-${companyId}`,
    {
      showMessage: true,
      showDismissButton: true,
    },
  );

  return (
    promoteInvestorMatching.showMessage && (
      <Todo>
        <Network />
        <span>
          <b>Investor matching</b> will get you hot leads from our network and increase your chances of building
          relevant connections.
        </span>
        <div
          onClick={() =>
            setPromoteInvestorMatching({
              ...promoteInvestorMatching,
              showDismissButton: false,
            })
          }
        >
          <BoostFundingRoundButton
            tag={`wants-investor-matching-${companyId}`}
            feedback="InterestedInInvestorMatchingDashboard"
            companyId={companyId}
          />
        </div>

        {promoteInvestorMatching.showDismissButton && (
          <Button
            kind="tertiary"
            color="grey"
            onClick={() =>
              setPromoteInvestorMatching({
                ...promoteInvestorMatching,
                showMessage: false,
              })
            }
          >
            Dismiss
          </Button>
        )}
      </Todo>
    )
  );
}
