import { Author } from 'domain/shared/author';
import React, { useState } from 'react';
import { Update as UpdateType } from 'types/content';
import { UserProfile } from 'types/user';
import RichText from 'ui/elements/text/RichText';
import Card from 'ui/views/cards/Card';
import CardStack from 'ui/views/cards/CardStack';
import MaxWidth from 'ui/views/containers/MaxWidth';
import BannerImage from 'domain/Updates/shared/Show/BannerImage';
import UpdateHeader from 'domain/Updates/shared/Show/UpdateHeader';
import UpdateActions from 'domain/Updates/shared/Show/UpdateActions';
import CommentsContainer from 'domain/Updates/Comments/CommentsContainer';
import Attachments from 'domain/Updates/shared/Show/Attachments';
import Byline from 'ui/domain/Byline';
import IframelyLinkPreview from 'domain/Updates/shared/Show/IframelyLinkPreview';
import classNames from 'classnames';
import PinIcon from 'ui/elements/icons/PinIcon';
import Tooltip from 'ui/elements/Tooltip';
import IconButton from 'ui/elements/icons/IconButton';
import { bluePlanetTheme } from 'ui/theme';
import { UpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import { UpdateContext } from 'domain/Updates/Comments/CommentField';
import { richTextContentPreviewParser } from '../utils';
import PreviewImages from './PreviewImages';
import UpdateStats from '../UpdateStats';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';
import Chip from 'ui/elements/Chip';
import { communityUrls } from 'urls';
import { visibilityDisplayString } from 'domain/Visibility/utils';
import { useMediaQuery } from '@mui/material';

interface Props {
  className?: string;
  update: UpdateType;
  creator?: UserProfile;
  author: Author;
  viewType?: ViewType;
  shownIn: ShownIn;
  api: UpdateAPI;
  actions?: React.ReactNode;
  canEditUpdate: boolean;
  showIsPinned?: boolean;
  updateContext: UpdateContext;
}

export type ViewType = 'preview' | 'comment' | 'read more';

export type ShownIn = 'community' | 'dashboard' | 'company' | 'boardroom' | 'other';

export default function ShowUpdate(props: Props) {
  const { update, api, showIsPinned, shownIn, actions, author, creator, updateContext } = props;

  const [viewType, setViewType] = useState<ViewType>(props.viewType || 'preview');
  const [likesCount, setLikesCount] = useState(update.likes);
  const [commentsCount, setCommentsCount] = useState(update.responses);
  const [hasLiked, setHasLiked] = useState(update.personalization.hasLiked);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [commentFieldFocus, setCommentFieldFocus] = useState<boolean>(false);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const onCommentClick = () => {
    onClick('comment');

    setCommentFieldFocus(true);
  };

  const onLike = async () => {
    if (!!update.archived) return;

    if (hasLiked) {
      await api.likes.unLike(update.id);
    } else {
      await api.likes.like(update.id);
    }
    setHasLiked(!hasLiked);
    const diff = hasLiked ? -1 : 1;
    setLikesCount(likesCount + diff);
  };

  const onClick = (updated: ViewType) => {
    if (viewType === 'preview') {
      api.markAsRead(update.id);
    }
    setViewType(updated);
  };

  const { previewHtmlString, imgUrls, firstEmbed } = richTextContentPreviewParser(
    update.content,
    200,
    update.attachments.length,
  );

  const cleaned = DOMPurify.sanitize(update.content, { ALLOWED_TAGS: [] });
  const updateContentHasText = cleaned !== '';

  const showAttachments =
    update.attachments.length > 0 && !(viewType === 'preview' && (imgUrls.length > 0 || firstEmbed));

  const showRichText =
    updateContentHasText ||
    imgUrls.length > 0 ||
    firstEmbed ||
    (update.attachments.length > 0 && viewType === 'preview');

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));

  return (
    <div className={props.className} ref={containerRef}>
      <CardStack>
        <Card>
          <div className="u-content-spacing-bottom u-flex-align-center u-flex-end">
            <div className="u-flex-grow">
              <div className="u-flex">
                <Byline author={author} timestamp={update.createdAt}>
                  {updateContext?.type === 'community' && (
                    <div className={'u-flex-align-baseline' + (isMobile ? ' u-flex--column' : ' u-flex--gap-half')}>
                      {shownIn !== 'community' && (
                        <span>
                          In{' '}
                          <Link to={communityUrls.overview(updateContext.community.slug)} className="text-link">
                            {/* {updateContext.visibility} */}
                            {updateContext.community.name}
                          </Link>
                        </span>
                      )}
                    </div>
                  )}
                  {(updateContext?.type === 'community' || updateContext?.type === 'company') &&
                    updateContext.visibility !== 'CommunityMembers' &&
                    updateContext.visibility !== 'Public' && (
                      <Chip color="grey-medium" label={visibilityDisplayString(updateContext.visibility)} />
                    )}
                </Byline>
              </div>
            </div>

            <div
              className="u-flex u-flex-align-center text-small"
              style={{ color: bluePlanetTheme.bluePlanetPalette.grey.dark }}
            >
              {showIsPinned && (
                <>
                  <div className="u-mobile-down-only">
                    <Tooltip
                      placement="top"
                      arrow={true}
                      open={tooltipIsOpen}
                      onClose={() => setTooltipIsOpen(false)}
                      onOpen={() => setTooltipIsOpen(true)}
                      disableHoverListener
                      title="Pinned by admin"
                    >
                      <div>
                        <IconButton
                          onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                          aria-label="Pinned by admin"
                          color="grey"
                        >
                          <PinIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                  <PinIcon
                    className="u-quarter-spacing-right u-mobile-up-only"
                    fontSize="small"
                    style={{ marginLeft: 2 }}
                  />
                  <span className="u-mobile-up-only text-small u-half-spacing-right">Pinned by admin</span>
                </>
              )}
              {actions}
            </div>
          </div>
          <BannerImage imageUrl={update.bannerImage} />
          {update.title && <UpdateHeader content={update} />}
          {showRichText && (
            <MaxWidth width="sm">
              <RichText
                initiallyExpanded={viewType === 'read more' || viewType === 'comment'}
                onClick={() => onClick('read more')}
                className={classNames('u-content-spacing-bottom', {
                  'data-content-read-more': viewType === 'preview',
                })}
              >
                {(isExpanded: boolean) => (isExpanded ? update.content : previewHtmlString)}
              </RichText>
            </MaxWidth>
          )}
          {firstEmbed && viewType === 'preview' && <RichText>{() => firstEmbed}</RichText>}
          {!firstEmbed && imgUrls.length > 0 && viewType === 'preview' && (
            <div onClick={() => onClick('read more')} style={{ cursor: 'pointer' }}>
              <PreviewImages imageUrls={imgUrls} />
            </div>
          )}
          {update.linkPreview && <IframelyLinkPreview link={update.linkPreview} />}
          {showAttachments && (
            <Attachments
              contentId={update.id}
              attachmentIds={update.attachments}
              listAttachments={api.attachments.list}
              downloadAttachment={api.attachments.download}
              isPreview={viewType === 'preview'}
              imgCount={imgUrls.length}
              onReadMore={() => onClick('read more')}
            />
          )}
          <UpdateStats
            api={api}
            numLikes={likesCount}
            numComments={commentsCount}
            canEditUpdate={props.canEditUpdate}
            update={update}
            onCommentClick={() => setViewType('comment')}
          />
        </Card>
        <UpdateActions
          creator={creator}
          hasLiked={hasLiked}
          content={update}
          onLike={onLike}
          onCommentClick={onCommentClick}
          getLikes={api.likes.list}
        />
        {((viewType !== 'preview' && commentsCount > 0) || viewType === 'comment') && (
          <CommentsContainer
            focus={commentFieldFocus}
            updateId={update.id}
            onCommentFieldFocusLost={() => setCommentFieldFocus(false)}
            commentsCount={commentsCount}
            getComments={api.comments.list}
            postComment={(contentId, comment) =>
              api.comments.post(contentId, comment).then(response => {
                setCommentsCount(commentsCount + 1);
                return response;
              })
            }
            replyToComment={(contentId, commentId, comment) =>
              api.comments.replyToComment(contentId, commentId, comment).then(response => {
                setCommentsCount(commentsCount + 1);
                return response;
              })
            }
            getReplies={api.comments.getReplies}
            deleteComment={api.comments.delete}
            context={props.updateContext}
          />
        )}
      </CardStack>
    </div>
  );
}
