import SeeMore from 'ui/modules/Pagination/SeeMore';
import Update, { PostedBy } from './Update';
import React from 'react';
import { communityUrls } from 'urls';
import { TinyCommunity } from 'types/company/community';
import MasonryGrid from 'ui/layout/masonry/MasonryGrid';
import useCommunityRole from 'hooks/useCommunityRole';
import { UpdatesFilter } from 'actions/content/updates';
import Resource from 'util/resource/Resource';
import { useCommunityUpdates } from './useCommunityUpdates';
import { PaginatedResult, PaginationOptions } from 'types/api';
import { getOrUndefined, IResource } from 'util/resource';
import useSelector from 'hooks/useSelector';
import { halfSpacing } from 'ui/theme/themeConstants';
import styled from '@emotion/styled';
import { CommunityUpdate } from 'types/content';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import classNames from 'classnames';
import { useRouteMatch } from 'react-router';
import { UserProfile } from 'types/user';
import { ICompany } from 'types/company';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';

const UpdateListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${halfSpacing};
`;

function ListWrapper({ useMasonry, children }: { useMasonry?: boolean; children: React.ReactElement }) {
  if (useMasonry) {
    return <MasonryGrid data-intercom-target="community-feed-widget">{children}</MasonryGrid>;
  }
  return <UpdateListContainer data-intercom-target="community-feed-widget">{children}</UpdateListContainer>;
}

export default function UpdateList(props: {
  community: TinyCommunity;
  filter: UpdatesFilter;
  onChange: () => void;
  paginationOptions: PaginationOptions;
  loadPage?: (page: number) => void;
  showSeeMore?: boolean;
  useMasonry?: boolean;
}) {
  const { resource } = useCommunityUpdates(props.community.id, props.paginationOptions, props.filter);
  return (
    <Resource resource={resource}>
      {updates =>
        updates.total === 0 && updates.page === 1 ? (
          <p className="text-small text-italic u-half-spacing-top">No updates have been posted yet</p>
        ) : (
          <List {...props} updates={updates} shouldHidePinnedPosts={false} />
        )
      }
    </Resource>
  );
}

export function UpdateListPreloaded(props: {
  resource: IResource<PaginatedResult<CommunityUpdate>>;
  community: TinyCommunity;
  onChange: () => void;
  paginationOptions: { page: number; limit: number };
  loadPage?: (page: number) => void;
  showSeeMore?: boolean;
  useMasonry?: boolean;
  shouldHideLoading?: boolean;
  shouldHidePinnedPosts?: boolean;
}) {
  const { resource, paginationOptions } = props;

  return (
    <Resource resource={resource} renderLoading={props.shouldHideLoading ? 'Nothing' : undefined}>
      {updates => {
        const start = (paginationOptions.page - 1) * paginationOptions.limit;
        const end = paginationOptions.page * paginationOptions.limit;

        return (
          <List
            {...props}
            shouldHidePinnedPosts={props.shouldHidePinnedPosts ?? false}
            updates={{ ...updates, values: updates.values.slice(start, end) }}
          />
        );
      }}
    </Resource>
  );
}

export function getPostedBy(
  update: CommunityUpdate,
  userProfiles: UserProfile[],
  companyProfiles: ICompany[],
  viewCompanyHref: (companyId: number) => string,
): PostedBy {
  if (update.postedBy.type === 'community') {
    return { type: 'community' };
  } else if (update.postedBy.type === 'user') {
    const cwUserId = update.postedBy.cwUserId;
    return { type: 'user', userProfile: userProfiles.find(u => u.cwUserId === cwUserId) };
  } else {
    const companyId = update.postedBy.companyId;
    const company = companyProfiles.find(c => c.id === companyId);
    return {
      type: 'company',
      company,
      viewCompanyHref: viewCompanyHref(companyId),
    };
  }
}

function List(props: {
  updates: PaginatedResult<CommunityUpdate>;
  community: TinyCommunity;
  onChange: () => void;
  shouldHidePinnedPosts: boolean;
  loadPage?: (page: number) => void;
  showSeeMore?: boolean;
  useMasonry?: boolean;
}) {
  const { community, updates, shouldHidePinnedPosts } = props;

  const { hasScope, myCompanyMemberships } = useCommunityRole(community.slug);
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const { resource: userProfileResource } = useSelfUserProfile();
  const me = getOrUndefined(userProfileResource);

  const { userProfiles, companyProfiles } = useSelector(state => ({
    userProfiles: state.userProfiles.items,
    companyProfiles: state.companyProfiles.items,
  }));
  const match = useRouteMatch();
  const withoutFirstPinnedUpdates = shouldHidePinnedPosts
    ? updates.values.filter(
        (communityUpdate, index) =>
          !((index === 0 || index === 1) && !communityUpdate.update.archived && communityUpdate.isPinned),
      )
    : updates.values;

  if (shouldHidePinnedPosts && withoutFirstPinnedUpdates.length === 0) {
    return <p className="text-small text-italic u-half-spacing-top">No updates have been posted yet</p>;
  }

  return (
    <div className="u-section-spacing-bottom">
      <ListWrapper useMasonry={props.useMasonry}>
        <>
          {withoutFirstPinnedUpdates.map(update => {
            const canEditPost =
              update.postedBy.type === 'community'
                ? hasScope('Write', 'Update', 'Admin')
                : update.postedBy.type === 'company'
                  ? myCompanyMemberships.some(
                      m => update.postedBy.type === 'company' && m.company.id === update.postedBy.companyId,
                    )
                  : update.postedBy.type === 'user'
                    ? update.postedBy.cwUserId === me?.cwUserId
                    : false;

            return (
              <Update
                key={`update-preview-${update.update.id}`}
                update={update}
                creator={userProfiles.find(u => u.cwUserId === update.update.creatorCwUserId)}
                community={community}
                postedBy={getPostedBy(update, userProfiles, companyProfiles, companyId =>
                  communityUrls.companies.view(match.url, companyId, 'community-feed'),
                )}
                shownIn="community"
                onEdit={props.onChange}
                onArchive={props.onChange}
                onDelete={props.onChange}
                canEditPost={canEditPost}
                canPinToFeed={hasScope('Write', 'Update', 'Admin') && update.postedBy.type === 'community'}
              />
            );
          })}
        </>
      </ListWrapper>
      {props.showSeeMore && (
        <SeeMore
          className={classNames('u-content-spacing-top', { ['u-content-spacing-left']: isMobile })}
          resource={updates}
          loadResource={opts => props.loadPage && props.loadPage(opts.page || 1)}
          limit={updates.limit}
        />
      )}
    </div>
  );
}
