import cx from 'classnames';
import { Author as AuthorType, AuthorImage } from 'domain/shared/author';
import React, { useState } from 'react';
import Timestamp from 'ui/elements/Timestamp';
import styles from './styles.scss';
import { userRole } from 'ui/domain/Users/userUtils';
import { BoardRoomPostContext, CompanyPostContext, RoomPostContext, PostSubtext } from './Titles';
import styled from '@emotion/styled';
import { quarterSpacing } from 'ui/theme/themeConstants';

const UserProfileDialog = React.lazy(() => import('domain/users/UserProfile/UserProfileDialog'));

interface Props {
  author: AuthorType;
  timestamp?: Date | string;
  className?: string;
  children?: React.ReactNode;
}

const SubtextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${quarterSpacing};
`;

export function AuthorLink(props: { name?: string; onClick?: () => void; className?: string }) {
  return props.onClick ? (
    <button onClick={props.onClick} className={props.className}>
      {props.name}
    </button>
  ) : (
    <span className={props.className}>{props.name}</span>
  );
}

export function AuthorRole(props: { author: AuthorType }) {
  switch (props.author.type) {
    case 'user':
      return props.author.position ? <span>, {userRole(props.author)}</span> : null;
    case 'company':
      if (!props.author?.user) return null;

      return props.author.user.position ? <span>, {userRole(props.author.user)}</span> : null;
    default:
      return null;
  }
}

export default function Byline({ author, timestamp, className, children }: Props) {
  const user = author.type === 'user' ? author : author.user;

  const [userDialogIsOpen, setUserDialogIsOpen] = useState(false);
  const handleUserClick = user?.cwUserId ? () => setUserDialogIsOpen(true) : undefined;

  function BylineAuthor() {
    if (author.type === 'company') {
      return <CompanyPostContext companyHref={author.companyhref} companyName={author.company?.name} />;
    }
    if (author.type === 'boardroom') {
      return (
        <BoardRoomPostContext
          companyHref={author.companyhref}
          companyName={author.company?.name}
          roomHref={author.roomHref}
        />
      );
    }
    if (author.type === 'room') {
      return (
        <RoomPostContext
          companyHref={author.companyhref}
          companyName={author.company?.name}
          roomHref={author.roomHref}
          roomName={author.roomName}
        />
      );
    } else {
      return <AuthorLink name={user?.name} onClick={handleUserClick} />;
    }
  }

  return (
    <>
      <div className={cx(className, styles.byline)}>
        {author.type === 'user' ? (
          <AuthorImage size={50} author={author} onClick={handleUserClick} />
        ) : (
          <AuthorImage size={50} author={author} companyHref={author.companyhref} />
        )}
        <div className={'text-metadata u-half-spacing-left'}>
          <span className="u-color-text">
            <BylineAuthor />
          </span>
          <SubtextWrapper>
            <PostSubtext context={author.type} user={user} />
            {timestamp && <Timestamp timestamp={timestamp} tooltipPlacement="bottom" />}
          </SubtextWrapper>
          {children}
        </div>
      </div>
      {userDialogIsOpen && user?.cwUserId && (
        <UserProfileDialog cwUserId={user?.cwUserId} onClose={() => setUserDialogIsOpen(false)} />
      )}
    </>
  );
}
